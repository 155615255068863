<template>

 <div>

    <b-modal
            ref="modalShowQR"
            centered
            hide-footer
            hideHeaderClose
            hideHeader
            size="sm"
            @hidden="closeModalQR"
          >

          <div class="d-block text-center">

            <b-row>

              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <p style="font-size: 20px;line-height: 30px;font-weight: bold;margin-bottom: 5px;">¿Te gustaría seguir apoyando a <br> <span style="text-transform: capitalize">{{displayName}}</span>?</p>
                <p style="text-align: center; font-size:14px;margin-bottom: 5px;">Comparte este código QR o el enlace con amigos</p>

               </b-col>

               <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                  <b-skeleton-img   no-aspect height="130px" width="50%" animation="fade"></b-skeleton-img>


                  </b-col>

                  <b-col  align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px"> 
                  <vue-qr  style="width: 200px !important;" :callback="getDataURL" :margin="margin" logoBackgroundColor="#fff" backgroundColor="#fff"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius"  :text="link" :correctLevel="correctLevel" :size="size"></vue-qr>
                    



                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 10px;">

                      <b-input-group>

                      <b-form-input
                          v-model="link"
                          readonly
                          />

                      <b-input-group-append>
                          <b-dropdown
                          variant="outline-primary"
                          right
                      >

                      <template  #button-content>
                              
                              Acciones <feather-icon
                              icon="ChevronDownIcon"
                              size="16"
                              style="color:white"
                              />

                              </template>
                          <b-dropdown-item        @click="onCopy"  >  <feather-icon
                              icon="CopyIcon"
                              size="15"
                              style="margin-right: 10px;"
                              /> Copiar link </b-dropdown-item>
                          <b-dropdown-item      @click="descargarQR">  
                              <feather-icon
                                          icon="DownloadCloudIcon"
                                          size="18"
                                          style="margin-right: 10px;"
                                      
                                          />Descargar QR</b-dropdown-item>

                              <b-dropdown-item     @click="sharedQR">  
                              <feather-icon
                                          icon="Share2Icon"
                                          size="18"
                                          style="margin-right: 10px;"
                                      
                                          />Compartir QR</b-dropdown-item>

                                      
                          </b-dropdown>
                      </b-input-group-append>

                      </b-input-group>


                      </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

                    <b-button
                    variant="primary"
                    block
                    @click="closeModalQR"
                  >
                    Entendido
                  </b-button>

                    </b-col>

            </b-row>


            </div>


          </b-modal>

 </div>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BSkeleton,BSkeletonImg,  BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,  BDropdown,BDropdownItem,
      } from 'bootstrap-vue'
      
      import VueQr from 'vue-qr'
      
      export default {
        components: {
            BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,  BDropdown,BDropdownItem,
            BSkeleton,BSkeletonImg, 
          BButton,
          BRow,
          BCol,
          BCard,
          VueQr
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['linkCopy', 'displayName','link'],
        data() {
      
      
          return {

            dataUrl:"",
            hideQR:true,
            size:800,
                logoScale:0.3,
                      logoMargin: 1,
                      logoCornerRadius:100,
                      margin:50,
                      correctLevel:3,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
            this.$refs.modalShowQR.show();
        },
        methods: {
  
            getDataURL(dataUrl,id){

this.dataUrl= dataUrl;
this.hideQR=false;

},
        closeModalQR(){

            this.$eventBus.$emit('reiniciarModalInvitacion')
        
        },

            
            descargarQR(){
    
    var link = document.createElement("a");
    link.download = "Link de promotor de " +  this.displayName;
    link.href =this.dataUrl;
    link.click();
     
     

  },
  sharedQR(){
    
    
    fetch(this.dataUrl).then(function (response) {
      return response.blob()
    }).then(function (blob) {
      let file = new File([blob], "Link de promotor.jpg", {type: 'image/jpeg'});
      let filesArray = [file];
      if (navigator.canShare && navigator.canShare({files: filesArray})) {
        navigator.share({
          files: filesArray
        }).then(() => console.log('Successful share'))
          .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          }));
      }else{

          this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }
    });


  

  },

    onCopy() {
      
      navigator.clipboard.writeText(this.linkCopy);
          this.$toast.success('Link de promotor copiado', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onError() {
          this.$toast.error('Falló en copiar el link de promotor', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },  

         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      