<template>

    <div>


        <div v-if="existePromotor">


          <modal-invitacion v-if="showModalQR" :key="componentKeyModalInvitacion"  :link-copy="linkCopy" :link="link"  :display-name="displayName"/>
          <b-row>
            <b-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            align="center"
           style="background: linear-gradient(87deg,#00838c,#036a72)!important;height: 200px; "
          >

            <img

            width="130px"
            style="margin-top:10px;"
              alt="EQCoop"
              src="/img/eqcoopblanco.svg"
            >

          </b-col>

        </b-row>

        <b-row style="margin-top: -115px;">

          <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">

            <b-row>

              <b-col cols="12" sm="12" md="12" lg="12" xl="2" align="center"></b-col>

<b-col cols="12" sm="12" md="6" lg="6" xl="4" align="center" >

  <b-row style="width: 100%;">

      <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loading" >
              <b-skeleton   width="100%" height="10px"  ></b-skeleton>
              <b-skeleton   width="80%" height="10px"  ></b-skeleton>
              <b-skeleton   width="60%" height="10px"  ></b-skeleton>
      </b-col>


      <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12">

          <h1 class="invitacion_txt" style="    color: white !important;">¡Hola! soy <span style="font-weight: bold;text-transform: capitalize">{{displayName}} </span> y te invito a abrir tu billetera digital de EQCoop</h1>

      </b-col>

      <b-col v-if="!isMobile"  class=" d-none d-sm-none d-md-block d-lg-block d-xl-block" align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:15px">

          <img

              width="350px"
              alt="EQCoop"
              src="/img/login_landing.png"
              >

          </b-col>

          

     


      </b-row>



  </b-col>

<b-col cols="12" sm="12" md="6" lg="6" xl="4" align="center">


          <b-card style="margin-top:25px">

            <b-row>

              <b-col cols="12" sm="12" md="2" lg="2" xl="2">

              </b-col>

              <b-col cols="4" sm="4" md="8" lg="8" xl="8">

                <b-avatar
                      variant="light"
                      :src="photo"
                 
                      class="border_profile2"
                      />


                </b-col>

                <b-col cols="8" sm="8" md="2" lg="2" xl="2">

                  <b-skeleton v-if="loading"  width="35px" height="35px" type="avatar" style="cursor:pointer; position: absolute; top: -10px; right: 45px;" ></b-skeleton>


                      <b-link v-else style="cursor:pointer; position: absolute; top: -10px; right: 45px;" @click="showQR" >

                          <b-avatar
                          size="35"
                          variant="light-primary"



                          >

                          <span class="d-flex align-items-center">


                            <svg class="icons_colors"  width="21" height="21" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>


                          </span>

                          </b-avatar>

                          </b-link>

                  <b-skeleton v-if="loading"  width="35px" height="35px" type="avatar" style="cursor:pointer; position: absolute; top: -10px; right: 0px;" ></b-skeleton>


                  <b-link v-else style="cursor:pointer; position: absolute; top: -10px; right: 0px;" @click="shared" >

                      <b-avatar
                      size="35"
                      variant="light-primary"



                      >

                      <span class="d-flex align-items-center">


                          <feather-icon

                      icon="Share2Icon"
                      size="19"
                      class="color_icon"

                      />

                      </span>

                      </b-avatar>

                      </b-link>

                </b-col>

            </b-row>


              <div v-if="loading">
                  <b-skeleton   width="100%" height="10px"  ></b-skeleton>
                  <b-skeleton   width="80%" height="10px"  ></b-skeleton>
                  <b-skeleton   width="60%" height="10px"  ></b-skeleton>
              </div>

        

              <div v-else class="biografiatext">

        

                  <read-more   more-str="Más información" :text="description" link="#" less-str="Menos información" :max-chars="140"></read-more>

              </div>

         

         
          </b-card>


          <b-card>

              <b-row v-if="loading">

                  <b-col cols="3" sm="3" md="3" lg="3" xl="3"> </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6"> 
                      <b-skeleton   width="100%" height="10px"  ></b-skeleton>
                  </b-col>

                  <b-col cols="3" sm="3" md="3" lg="3" xl="3"> </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 
                      <b-skeleton   width="100%" height="50px"  ></b-skeleton>
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 
                      <b-skeleton   width="100%" height="50px"  ></b-skeleton>
                  </b-col>

                  <b-col cols="5" sm="5" md="5" lg="5" xl="5"> </b-col>

                  <b-col cols="2" sm="2" md="2" lg="2" xl="2"> 
                      <b-skeleton   width="100%" height="10px"  ></b-skeleton>
                  </b-col>

                  <b-col cols="5" sm="5" md="5" lg="5" xl="5"> </b-col>


                  <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 
                      <b-skeleton   width="100%" height="50px"  ></b-skeleton>
                  </b-col>

              </b-row>

              <b-row v-else>

                <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style="text-align: center;"
                    >
                    <p style="color: #2f2e72 !important;font-weight: bold;font-size: 18px;text-align: center;margin-bottom: 15px;">¿Cómo deseas registrarte?</p> 
                
              
                    </b-col>

                <formulario  :is-login="isLogin" :promo-code="promoCode" :texto-button="textoButton" />
           
              </b-row>

             

              </b-card>


</b-col>



<b-col cols="12" sm="12" md="12" lg="12" xl="2" align="center"></b-col>



<b-col
  style="color:black !important;margin: auto; "
  sm="12"
  md="12"
  lg="12"
  xl="12"
  class="text-center align-items-center justify-content-xl-between"

>
<hr>


  <a
    target="_blank"
    title="Facebook de EQCoop"
    href="https://www.facebook.com/eqpay.oficial"
  >   <feather-icon
   icon="FacebookIcon"
   size="25"
   class="redes"
   /></a>
  <a
    target="_blank"
    title="Instagram de EQCoop"
    href="https://www.instagram.com/eqpay.oficial"
  > <feather-icon
   icon="InstagramIcon"
   size="25"
   class="redes"
   /></a>
  <a
    target="_blank"
    title="Email de EQCoop"
    href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
  > <feather-icon
   icon="MailIcon"
   size="25"
   class="redes"
   /></a>
  <a
    target="_blank"
    title="Tiktok de EQCoop"
    href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
  > <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#00838C"
    widht="10"
    height="22"
    viewBox="0 0 448 512"
  ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

  <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
    href="#"
    target="_blank"
  >Términos y condiciones</a> - <b-link
    :to="{ name: 'privacidad'}"
    target="_blank"
  >Política de privacidad y condiciones de servicios</b-link> -  <a
    href="#"
    target="_blank"
  >Reclamos</a></p>

  <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
    href="https://web.eqcoops.com"
    target="_blank"
  >- EQCoop</a></p>

</b-col>

            </b-row>

          </b-col>



        </b-row>

        </div>

        <b-row v-else>

            <not-promotor/>

            </b-row>
        
        
      </div>
        
        </template>
        
        <script>
        import {
          BButton,VBPopover,BRow, BCol, BCard,BSkeleton,BSkeletonImg, BAvatar, BLink, 
        } from 'bootstrap-vue'
      
        import NotPromotor from './NotPromotor.vue'
        import ModalInvitacion from './ModalInvitacion.vue'
        import Formulario from '../authentication/Formulario.vue'
        export default {
          components: {
            BButton,
            Formulario,
            NotPromotor,
            ModalInvitacion,
            BSkeletonImg,
            BLink,
            BSkeleton,
            BAvatar,
            BRow,
            BCol,
            BCard,
           
          },
            directives: {
            'b-popover': VBPopover,
          },
          data() {
            document.body.classList.remove('dark-layout')
            let promoCode=this.$route.params.promoCode;
    
      
    
        
            return {
                loading:true,
                isLogin:true,
                existePromotor:true,
                isMobile:this.$isMobile,
                displayName:"",
                photo:"",
                description:"",
                promoCode:promoCode,
                year: new Date().getFullYear(),
                textoButton:"Registrarte con",
                link:"",
                showModalQR:false,
                componentKeyModalInvitacion:0
              

                     
              
            }
          },
          computed: {
        
          },
          watch: {
    
            

    
        
          },
          created(){
             
            document.title = 'EQCoop - Link de referido';
    
          
    
          },
            mounted() {
              this.getInfo();
              this.$eventBus.$on('reiniciarModalInvitacion', () => {
              this.componentKeyModalInvitacion += 1
              this.showModalQR = false;
            });
    

        
                    
           
          },
          methods: {


            showQR(){

              this.showModalQR=true;

            },
            shared(){

             

            if (navigator.share) {
                  navigator.share({
                    title: 'EQCoop - Link de promotor de ' + this.displayName,
                    text:   '¡Hola! soy ' + this.displayName + ' y te invito a abrir tu billetera digital de EQCoop',
                    url: window.location.href,
                  })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                }

            },

    
            getInfo(){
          this.loading=true;
         
      
    
        this.$https.post('/links/getUserByPromocode/', { promoCode: this.promoCode }).then(response => {
            
       
          if (response.data.code == 200) {
         

             this.photo=response.data.photo;
             this.link=window.location.href;
             this.displayName= response.data.displayName.toLowerCase();

             this.linkCopy='¡Hola! soy ' + this.displayName + ' y te invito a abrir tu billetera digital de EQCoop. Regístrate aquí: ' + this.link;
    
              document.title = 'EQCoop - Link de referido de ' + this.displayName;
              this.description="Aquí podrás realizar todos tus movimientos financieros como cobros, pagos, transferencias y transacciones con dólares y criptomonedas de una manera fácil, rápida y segura.<br><br>Además podrás traer tu dinero desde PayPal, ahorrar y generar dinero, promocionar tu negocio o servicio, solicitar créditos inmediatos y muchas más opciones que te brinda nuestra billetera.";
    
              this.loading=false;
       
          } else {
    

              if (response.data.code == 404) {

                this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
    
                this.existePromotor=false;
    
              }else{ 
                this.getInfo();
    
              }
              
            
          }
        }).catch(error => {
             this.getInfo();
        })
        },
    
       
        
        
        
          },
        }
        </script>
        
        <style lang="scss">
    
    
            
        </style>
        