<template>

<b-row style="width: 100%;">




<b-col
  sm="12"
  md="12"
  lg="12"
  xl="12"
  class="mb-1 mx-auto"
>
  <b-button
    id="btn_google"
    ref="btn_google"
    :disabled="isActiveGoogle"
    class="btn-primary_google"
    block
    style="justify-content: space-between;display: flex;width: 100%;border-radius:50px"
    @click="autenticacion('google')"
  >

  <img src="/img/google.svg" style="width: 18px;" />

  <span style="font-size: 15px;">{{textoButton}} Gmail</span>

  </b-button>

  <b-button
    id="btn_google"
    ref="btn_google"
    :disabled="isActiveGoogle"
    class="btn-primary_facebook"
    block
    style="justify-content: space-between;display: flex;width: 100%;border-radius:50px; margin-top: 20px;"
    @click="autenticacion('facebook')"
  >

  <img src="/img/facebook.svg" style="width: 18px;" />

  <span style="font-size: 15px;">{{textoButton}} Facebook</span>

  </b-button>

</b-col>

<b-col
  sm="12"
  md="12"
  lg="1"
  xl="1"
  class=" mx-auto"
/>
</b-row>    
        </template>
        
        <script>

            /* eslint-disable global-require */
        import { BRow, BCol, BAlert, BLink, BButton,BForm,  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,BAvatar } from 'bootstrap-vue'

        import PincodeInput from 'vue-pincode-input'
        import firebase from 'firebase/compat/app'
        import 'firebase/compat/auth'
        import 'firebase/compat/app-check'
     
        
        export default {
          components: {
            BRow,
            BFormGroup,BInputGroup,BInputGroupAppend,
            PincodeInput,
            BCol,
            BFormInput,
            BButton,
            BAlert, BLink,BForm,
            BAvatar
  
          },
          props: ['isLogin', 'promoCode', 'textoButton'],
          data() {
        
          
            return {
            
          
                results: null,
            countryCode: '',
            phoneNumber: null,
            isActive: true,
            isActiveGoogle: false,
            confirmationResult: null,
            installEvent:null,
            recaptchaVerifier: null,
            recaptchaWidgetId: null,
            confirmResult: null,
            code: '',
            token: null,
            appCheck: null,
            isDisabled:false,
            password:"",
            passwordFieldTypeNew: 'password',
        
            }
          },
          created() {
        
          },
          computed: {
            passwordToggleIconNew() {
              return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
          },
          watch: {

                results(newValue) {
                    if (newValue.isValid) {
                    this.isActive = false
                    this.phoneNumber = newValue.e164
                    this.countryCode = newValue.countryCode
            
                    } else {
                    this.isActive = true
                    this.phoneNumber = ''
                    this.countryCode = ''
                    this.code = ''
                
                    }
                },
                code(newValue) {
                    if (newValue.length == 6) {
                    this.verifyCode(newValue)
                    }
                },

                },
        async  mounted() {
          firebase.auth().useDeviceLanguage();

          this.appCheck = firebase.appCheck()


          this.appCheck.activate(
          '6LdazQMpAAAAAONOLS6DmRFWQJrCE-nzNbPXJOTG',

          // Optional argument. If true, the SDK automatically refreshes App Check
          // tokens as needed.
          true,
          )


            let tokenAux= await this.appCheck.getToken();

            this.token= tokenAux.token;

   

            this.appCheck.setTokenAutoRefreshEnabled(true) 


        
          },
          methods: {

            togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

            onSubmitPassword(event){
              event.preventDefault();

              const userId_json = { phone: this.phoneNumber, password:this.password }
              const user_string = JSON.stringify(userId_json)

              const userEncripted = this.$encryptBackoffice.encrypt(user_string)
                this.isDisabled = true;

                document.getElementById('btn_search').innerHTML = '<img style="margin-left:10px; width:15px " src="/img/loading2.svg" alt="Loading" />  Iniciando sesión'


            this.$https.post('/acceso/loginPassword/', { tokenAuth: this.token, userId: userEncripted }).then(response => {

              document.getElementById('btn_search').innerHTML = 'Iniciar sesión'

              if (response.data.code == 200) {
                const userData = response.data.user



                localStorage.setItem('userData', JSON.stringify(userData))



                  if(this.isLogin){

                    this.$router.push('/dashboard')

                    }else{
                     
                        this.$toast.success("Bienvenido " + userData.displayName, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })

                            location.reload();

                    }




                
              } else {
                     
                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                this.isDisabled = false
                 
              }
            }).catch(error => {
              this.isDisabled = false
              this.$toast.error("1) Ocurrió un error inesperado: " + error, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })


      


            },

        
            autenticacion(type) {
      let provider = ''

      if (type == 'google') {
        provider = new firebase.auth.GoogleAuthProvider()
        this.isActiveGoogle = true
        
      } else {
        provider = new firebase.auth.FacebookAuthProvider()
      }

      firebase.auth()
        .signInWithPopup(provider)
        .then(result => {
          const { user } = result

         
            const datos = { userId: user.uid, promoCode:this.promoCode }
         
              this.isActive = true;

           
   
              this.$swal({

                  html:
                  '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:13px; text-align:center; font-weight:bold">Iniciando sesión, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  position: 'center',
                  });


            this.$https.post('/acceso/login/', { tokenAuth: this.token, datos: datos }).then(response => {
              this.$swal.close()
            
              if (response.data.code == 200) {
                const userData = response.data.user


        
                localStorage.setItem('userData', JSON.stringify(userData))

   
             

                let isNewUser= response.data.isNewUser;



                if(this.isLogin){

                    this.$router.push('/dashboard')

                    }else{
                     
          

                            location.reload();

                    }


                
              } else {
                     
                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                this.isActiveGoogle = false
                 
              }
            }).catch(error => {
              this.$toast.error("2) Ocurrió un error inesperado: " + error, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })
    
        }).catch(error => {
          // Handle Errors here.

          const errorCode = error.code;

       

          this.isActiveGoogle = false

          if (errorCode == 'auth/popup-closed-by-user') {

          }

          if (errorCode == 'auth/user-disabled') {
            this.$toast.error('Lo sentimos, su cuenta se encuentra inhabilitado actualmente', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }

          if (errorCode == 'auth/account-exists-with-different-credential') {
            this.$toast.error('No es posible su logeo o registro porque esta cuenta ya se encuentra registrada', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }

          // ...
        })
    },

    verifyCode(newValue) {

       this.$refs.modalLogin.hide()

      
    this.$swal({

        html:
        '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:13px; text-align:center; font-weight:bold">Iniciando sesión, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: 'center',
        });



      this.isActive = true
       this.isActiveGoogle = true
    

      this.confirmResult.confirm(newValue)
        .then(resultUser => {

   

            const { user } = resultUser

            const datos = { userId: user.uid, promoCode:this.promoCode }
          

            this.$https.post('/acceso/login/', { tokenAuth: this.token, datos: datos }).then(response => {

               this.$swal.close()

              
         
              if (response.data.code == 200) {

                  this.$refs.modalLogin.hide()

                const userData = response.data.user
              

       
                localStorage.setItem('userData', JSON.stringify(userData))
            
             




                let isNewUser= response.data.isNewUser;

     
                if(this.isLogin){

            
                  if(userData.hasPassword){
                  
                    this.$router.push('/dashboard')
                  }else{
                
                    this.$router.push('/password')
                  }

                  

                }else{

                   
                    this.$toast.success("Bienvenido " + userData.displayName, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })

                            location.reload();

                }

               
               

                if(isNewUser){
                  this.eqcoins(response.data.eqpoints, "ser nuevo usuario en EQCoop", "Registro");

                }

                

             

              
              } else {
                
                this.isActive = false;
                 this.isActiveGoogle = false;
                  this.$refs.modalLogin.show();
                   this.code = ''
                    

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }
            }).catch(error => {
              this.isActive = false
              this.code="";
              this.isActiveGoogle = false;
              this.$toast.error("3) Ocurrió un error inesperado: " + error, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })

          
        })
        .catch(error => {

            this.$swal.close();




          this.code = ''

        
          const errorCode = error.code;
        

          this.isActive = false
        this.isActiveGoogle = false;

          if (errorCode == 'auth/user-disabled') {
            this.$toast.error('Lo sentimos, su cuenta se encuentra inhabilitado actualmente', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }

          if (errorCode == 'auth/account-exists-with-different-credential') {
            this.$toast.error('No es posible su logeo o registro porque esta cuenta ya se encuentra registrada', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }

          if(errorCode == 'auth/invalid-verification-code'){

             this.$refs.modalLogin.show();

              this.$toast.error('El código ingresado es incorrecto, por favor intente de nuevo', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

          }

    

        })
    },

    onSubmitPhone(event){
      event.preventDefault();

      

      this.isActive = true

      document.getElementById('log-in').innerHTML = '<div style="justify-content: space-between;display: flex;"><div class="loader2"> </div>Enviando código SMS</div> '


      firebase.auth().signInWithPhoneNumber(this.phoneNumber, this.recaptchaVerifier)
        .then(confirmationResult => {
          this.confirmResult = confirmationResult

          document.getElementById('log-in').innerHTML = this.textoButton+' celular'



          this.code = ''

  
          this.$refs.modalLogin.show()
          this.isActive = false
        })
        .catch(error => {
          const errorCode = error.code
          document.getElementById('log-in').innerHTML = this.textoButton+' celular'

          if (errorCode == 'auth/too-many-requests') {
            this.$toast.warning('Hemos bloqueado todas las solicitudes de este dispositivo debido a actividad inusual', {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

            this.isActive = false
   

          } else {

            
            this.isActive = false

              if(error.message === "Cannot read properties of null (reading 'verify')"){

                   this.$toast.warning("Token mal generado, se hará un reload de la página", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })



                location.reload();


                
              }else{
                this.$toast.warning(error.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

              }



         
         
          }
        })

    },

          },
        }
        </script>
        
        <style lang="scss" >
        
    
        
        </style>
        
